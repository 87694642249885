import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "./services/Translate.service";
import {BrowserRouter as Router} from 'react-router-dom';
import { init } from "arsa";
import { medic, path, themeData } from "./environment/environment";
const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);


init({
  entryPath: `${path}${medic}`,
  isEmulate: true,
  environmentData: {
    genericQuerie: `${path}${medic}generic-querie`,
    petition: `${path}${medic}petition/`,
    loadFileOnly: `${path}${medic}load-only-file`,
    sendEmail: `${path}${medic}sendMail`,
  },
  themeData: themeData,
  SYSTEM_CONSTANTS: {
    mobileMinimum: 900,
    drawerWidth: 600,
    loginTemplate: "MedicApp",
  },
  userTagSession: "merryMeSession"
});

root.render(
    <I18nextProvider i18n={i18n}>
      <Router>
        <App/>
      </Router>
    </I18nextProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
