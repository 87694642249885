import Home from "./pages/home/Home";
import { Privacy } from "./pages/privacy/Privacy";

export const routes = [
  {
    to: "/",
    path: "/home",
    Component: Home,
    isPublic: true,
    extraData: {},
  },
  {
    to: "/",
    path: "/",
    Component: Home,
    isPublic: true,
    extraData: {},
  },
  {
    to: "/privacy-policy",
    path: "/privacy-policy",
    Component: Privacy,
    isPublic: true,
    extraData: {},
  },
];
