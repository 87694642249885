import { SqlGenericService } from "arsa";

export class PrivacyPresenter {
  static findSection(setHtml) {
    let sql: string = `SELECT * FROM page_section WHERE id = 1`;
    SqlGenericService.excecuteQueryStringReference(sql, "getSectionPrivacy").then(
      (response: any) => {
        setHtml(response.data.data);
      }
    );
  }
}
