import { useEffect } from "react";
import "./belt.scss";
import { useTranslation } from "react-i18next";

function Belt({
  extraClass,
  premises,
  title,
  description,
}: {
  extraClass?: string;
  title?: string;
  description?: string;
  premises?: any[];
}) {
  useEffect(() => {}, []);

  const { t } = useTranslation();

  return (
    <section className={`belt ${extraClass}`}>
      <div className="int">
        <p className="title">{title}</p>
        <p className="description">{description}</p>
        <section className="leseparat">
          {premises?.map((premise: any, prindex) => {
            return (
              <div className="premise" key={`prindex-${prindex}`}>
                <p className="title">{premise.title}</p>
                <p className="description">{premise.description}</p>
              </div>
            );
          })}
        </section>
      </div>
    </section>
  );
}

export default Belt;
