import { useEffect } from "react";
import "./banner.scss";

declare let Swiper;

function Banner({
  cssClass = `${Math.round(Date.now() / 1000)}`,
  title = "",
  subtitle = "",
  hasTitle = false,
  hasPagination = false,
  breakpoints = null,
  slidesPerView = 1,
  spaceBetween = 1,
  extraClassTitle = "",
  hasArrow = false,
  hasScrollbar = false,
  breakpointsForContainer = true,
  children,
}: {
  cssClass?: string;
  title?: string;
  subtitle?: string;
  hasTitle?: boolean;
  hasPagination?: boolean;
  breakpoints?: any;
  slidesPerView?: number;
  spaceBetween?: number;
  extraClassTitle?: string;
  hasArrow?: boolean;
  hasScrollbar?: boolean;
  breakpointsForContainer?: boolean;
  children: any;
}) {
  useEffect(() => {
    if (!breakpoints) {
      breakpoints = {
        640: {
          slidesPerView: slidesPerView,
          spaceBetween: spaceBetween,
        },
        768: {
          slidesPerView: slidesPerView,
          spaceBetween: spaceBetween,
        },
        1024: {
          slidesPerView: slidesPerView,
          spaceBetween: spaceBetween,
        },
      };
    }

    init();
  }, []);

  function init() {
    setTimeout(() => {
      console.log(`css-${cssClass}`);

      //console.log(document.getElementById(`css-${this.cssClass}`));

      const swiper = new Swiper(`.css-${cssClass}`, {
        // Optional parameters
        effect: 'coverflow',
        //grabCursor: true,
        centeredSlides: true,
        /* coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }, */
        direction: "horizontal", 
        //loop: true,
        breakpointsBase: "container",

        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },

        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        slidesPerView: slidesPerView,
        spaceBetween: spaceBetween,

        // And if we need scrollbar
        scrollbar: {
          el: ".swiper-scrollbar",
        },

        breakpoints: breakpoints,
        speed: 400,
        autoplay: {
          delay: 5000,
        },
      });

      console.log(swiper);
    }, 1200);
  }

  return (
    <>
      {hasTitle && (
        <section className={`swp-title ${extraClassTitle} animated fadeInRight`}>
          {title && title.length && <p>{title}</p>}
          {subtitle && subtitle.length && (
            <p className="subtitle">{subtitle}</p>
          )}
        </section>
      )}
      <div className={`swiper css-${cssClass}`}>
        <div className="swiper-wrapper">{children}</div>
        {hasPagination && <div className="swiper-pagination"></div>}

        {hasArrow && <div className="swiper-button-prev"></div>}
        {hasArrow && <div className="swiper-button-next"></div>}

        {hasScrollbar && <div className="swiper-scrollbar"></div>}
      </div>
    </>
  );
}

export default Banner;
