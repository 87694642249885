import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Privacy.scss";

import { ArsaContainer, ArsaButton } from "arsa";
import ArsaFooter from "../../components/footer/ArsaFooter";
import Header from "../../components/header/Header";
import Banner from "../../components/banner/Banner";
import InfoSquare from "../../components/info-square/InfoSquare";
import Belt from "../../components/belt/Belt";
import PricingCard from "../../components/pricing-card/PricingCard";
import ContactForm from "../../components/contact-form/ContactForm";
import ArsaTeam from "../../components/arsa-team/ArsaTeam";
import { PrivacyPresenter } from "./presenter/Privacy.presenter";

export function Privacy({}) {
  const { t, i18n } = useTranslation();
  const [headUp, setHeadUp] = useState(false);
  const [upActive, setUpActive] = useState(false);
  const [html, setHtml] = useState<any>([]);

  useEffect(() => {
    init();
  }, []);

  function init() {
    console.log("init");

    setTimeout(() => {
      let el: any = document.getElementById("hommie");
      if (el) {
        el.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 100);

    PrivacyPresenter.findSection(setHtml);
  }

  return (
    <section className="house" id="house">
      <Header
        menues={[
          {
            name: "home.home",
            onClick: () => {
              let el: any = document.getElementById("house");
              el.scrollIntoView({ behavior: "smooth", block: "start" });
            },
          },
          {
            name: "home.news",
            onClick: () => {
              let el: any = document.getElementById("news");
              el.scrollIntoView({ behavior: "smooth", block: "center" });
            },
          },
          {
            name: "home.pricing",
            onClick: () => {
              let el: any = document.getElementById("pricing-space");
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            },
          },
          {
            name: "home.team",
            onClick: () => {
              let el: any = document.getElementById("team");
              el.scrollIntoView({ behavior: "smooth", block: "center" });
            },
          },
          {
            name: "home.contact",
            onClick: () => {
              let el: any = document.getElementById("contact");
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            },
          },
        ]}
        upActive={headUp}
        isBackActive={true}
      />

      {/* <section id="team">
        <ArsaTeam
          title={t("team.title")}
          description={t("team.spec")}
          team={cofouders}
        />
      </section>
 */}

      <div dangerouslySetInnerHTML={{ __html: html[0]?.content }} />

      <section id="contact">
        <ContactForm />
      </section>

      <ArsaFooter />

      <div
        className={`arrup ${
          upActive ? "animated fadeInRight" : "animated fadeOutRight"
        }`}
        onClick={() => {
          let el: any = document.getElementById("hommie");
          el.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        <span
          className={`material-icons`}
          style={{
            fontSize: "44px",
          }}
        >
          expand_less
        </span>
      </div>
    </section>
  );
}
