import { useEffect } from "react";
import "./pricing-card.scss";
import { useTranslation } from "react-i18next";

function PricingCard({
  extraClass,
  price,
}: {
  extraClass?: string;
  price?: any;
}) {
  useEffect(() => {}, []);

  const { t, i18n } = useTranslation();

  return (
    <section className={`pricing-card ${extraClass}`}>
      <div className="int">
        <p className="title">{i18n.language == 'es' ?  price.title_es : price.title_en}</p>
        <p className="description">{i18n.language == 'es' ? price.description_es : price.description_en}</p>
        <p className="price">{price.price_mxn}</p>

        <button>
          {t("home.wantit")}
        </button>

        <div className="line">

        </div>


        <section className="leseparat">

          <p className="includs">{t("home.includs")}</p>
          {
            (i18n.language == "es" ? price.json_es : price.json_en)?.map((data:any)=>{
              return <div className="point">
                <span className="material-icons">task_alt</span>
                <p>{data}</p>
              </div>
            })
          }
        </section>
      </div>

      <div className="sell">
        <span className="material-icons">sell</span>
      </div>
    </section>
  );
}

export default PricingCard;
