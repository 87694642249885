import { useEffect, useState } from "react";
import "./arsa-team.scss";
import { useTranslation } from "react-i18next";
import ArsaMap from "../arsa-map/ArsaMap";
import { ArsaForm } from "arsa";

function ArsaTeam({
  extraClass,
  team,
  title,
  description,
}: {
  extraClass?: string;
  title?: string;
  description?: string;
  team?: any[];
}) {
  useEffect(() => {}, []);
  const { t, i18n } = useTranslation();
  return (
    <section className={`arsa-team ${extraClass}`}>
      <div className="int">
        <p className="title">{title}</p>
        <p className="description">{description}</p>
        <section className="leseparat">
          {team?.map((t: any, trindex) => {
            return (
              <div className="team" key={`prindex-${trindex}`}>
                <div className="cofun">
                  <img src={t.img} />
                </div>

                <p className="title">
                  {" "}
                  {i18n.language == "es" ? t.title_es : t.title_en}
                </p>
                <p className="description">
                  {i18n.language == "es" ? t.description_es : t.description_en}
                </p>
              </div>
            );
          })}
        </section>
      </div>
    </section>
  );
}

export default ArsaTeam;
