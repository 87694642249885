import { IonApp, IonMenu, setupIonicReact } from "@ionic/react";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "./styles.scss";
import gif from "./assets-site/imgs/logos/logoOlam.png";
/* Theme variables */
import { menuController } from "@ionic/core/components";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import {
  ArsaLoader,
  ArsaMenu,
  ArsaSpinner,
  EventService,
  HttpService,
  LocalStorageEncryptService,
  UtilService,
  init,
  useDeviceDetect,
  useMenuOpen,
  usePipeDate,
  useTheme,
  useUserSession,
  subscriptions,
} from "arsa";
/* import {
  ArsaLoader,
  ArsaMenu,
  ArsaSpinner,
  EventService,
  HttpService,
  LocalStorageEncryptService,
  UtilService,
  init,
  useDeviceDetect,
  useMenuOpen,
  usePipeDate,
  useTheme,
  useUserSession,
  subscriptions,
} from "../../custom"; */
import "arsa/dist/style.css";
import { useTranslation } from "react-i18next";
import { LoginRoute } from "./Login.route";
import { ProtectedRoute } from "./Protected.route";
//import logo from "./assets/imgs/logos/logoApp.png";
import {
  SYSTEM_CONSTANTS,
  eventKeys,
  medic,
  path,
  themeData,
} from "./environment/environment";
import NotFound from "./pages/404/not-found";
import { routes } from "./routes";
import "./theme/variables.css";

setupIonicReact();

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  //padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${SYSTEM_CONSTANTS.drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  position: "relative",
}));

const App = (props) => {
  const { t, i18n } = useTranslation();
  const { theme } = useTheme();
  const { user } = useUserSession();
  const device: any = useDeviceDetect();
  const navigate = useNavigate();
  const { menuOpen, setEvent } = useMenuOpen();

  const [isOpen, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [initApp, setInitApp] = useState(false);
  const [renderMenu, setRenderMenu] = useState(false);
  const [isOpenDrawer, setOpenDrawer] = useState(true);
  const [loader, setLoader] = useState(true);
  const [loaderTemplate, setLoaderTemplate] = useState(true);
  const [menuItems, setMenuItems] = useState([
    {
      id: 1,
      name: "ABOUT",
      icon: "help",
      active: false,
      handle: () => {
        goToMenu("about", 0);
      },
    },

    {
      id: 1,
      name: "WhatsApp",
      icon: "chat",
      active: false,
      handle: async () => {
        await menuController.close();
        /* alertService.openModalAlert(
          t("alerts.need"),
          t("alerts.worry"),
          t("alerts.need-button"),
          () => {}
        ); */
        goToMenu("noCharge", 1, false);
      },
    },
  ]);

  useEffect(() => {
    //HttpService.get("https://www.olam-systems.com.mx/olam/utils/getCountries");
    loadTheme();
    loadLanguages();
    subscribes();

    //init library
    console.log("INIT LIBRARY");

    setEvent(false);
    setInitApp(true);
    console.log("inited");

    setRenderMenu(true);

    setTimeout(() => {
      setLoader(false);

      setTimeout(() => {
        setLoaderTemplate(false);
      }, 2500);
    }, 2000);
  }, []);

  function loadLanguages() {
    let language: any =
      LocalStorageEncryptService.getFromLocalStorage("language");
    if (!language) {
      LocalStorageEncryptService.setToLocalStorage("language", "es");
    }
  }

  const loadTheme = () => {
    const t: any = LocalStorageEncryptService.getFromLocalStorage("theme");
    if (!t) {
      LocalStorageEncryptService.setToLocalStorage("theme", "blue");
    }

    EventService.get("openMenu").subscribe((data: any) => {
      console.log(data);
      setOpen(true);
    });
  };

  const subscribes = () => {
    EventService.clearSubjects({
      [eventKeys.loader]: eventKeys.loader,
      [eventKeys.menuGlobal]: eventKeys.menuGlobal,
    });

    subscriptions[eventKeys.loader] = EventService.get(
      eventKeys.loader
    ).subscribe((data: any) => {
      setMessage(data.message);
      setShow(data.show);
    });

    subscriptions[eventKeys.menuGlobal] = EventService.get(
      eventKeys.menuGlobal
    ).subscribe(async (data: any) => {
      let isOpen: any = await menuController.isOpen();
      if (data && !isOpen) {
        await menuController.open("menu-global");
      }
    });
  };

  /** */
  const goToMenu = (
    url: string,
    position: number,
    isRouting: boolean = true
  ) => {
    menuItems.forEach((element) => {
      element.active = false;
    });

    menuItems[position].active = true;
    if (isRouting) {
    }
  };

  function getTranslate(date) {
    return UtilService.replaceAll(date, "of", t("menu.of"));
  }

  return (
    <>
      {show ? (
        <div className={`spinner-wrapper`}>
          {message && message.length > 0 && (
            <div
              style={{
                fontWeight: "700",
                textAlign: "center",
                fontSize: "15px",
              }}
            >
              {message}
            </div>
          )}
          <img src={gif} />
        </div>
      ) : (
        <></>
      )}

      {initApp && (
        <IonApp>
          <Drawer
            sx={{
              width: SYSTEM_CONSTANTS.drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: SYSTEM_CONSTANTS.drawerWidth,
                boxSizing: "border-box",
                background: "#fff",
              },
            }}
            anchor="right"
            open={isOpen}
            variant="persistent"
            ModalProps={{
              keepMounted: false,
            }}
          >
            <section className="menues">
            {[
              {
                name: "home.home",
                onClick: () => {
                  let el: any = document.getElementById("house");
                  el.scrollIntoView({ behavior: "smooth", block: "start" });
                  if (isOpen) {
                    setOpen(!isOpen);
                  }
                },
              },
              {
                name: "home.news",
                onClick: () => {
                  let el: any = document.getElementById("news");
                  el.scrollIntoView({ behavior: "smooth", block: "end" });
                  if (isOpen) {
                    setOpen(!isOpen);
                  }
                },
              },
              {
                name: "home.pricing",
                onClick: () => {
                  let el: any = document.getElementById("pricing-space");
                  el.scrollIntoView({ behavior: "smooth", block: "end" });
                  if (isOpen) {
                    setOpen(!isOpen);
                  }
                },
              },
              {
                name: "home.team",
                onClick: () => {
                  let el: any = document.getElementById("team");
                  el.scrollIntoView({ behavior: "smooth", block: "end" });
                  if (isOpen) {
                    setOpen(!isOpen);
                  }
                },
              },
              {
                name: "home.contact",
                onClick: () => {
                  let el: any = document.getElementById("contact");
                  el.scrollIntoView({ behavior: "smooth", block: "end" });
                  if (isOpen) {
                    setOpen(!isOpen);
                  }
                },
              },
            ].map((menu: any, mindex) => {
              return  <section
              className="menu"
              key={`hdr-${mindex}`}
              onClick={menu.onClick}
            >
              {t(menu.name)}
            </section>;
            })}
            </section>
          </Drawer>

          <Main
            open={!isOpen}
            id="hommie"
            className="scf"
            onClick={() => {
              if (isOpen) {
                setOpen(!isOpen);
              }
            }}
          >
              <Routes>
                {routes.map((route: any) => {
                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={
                        route.isInit ? (
                          <LoginRoute>
                            <route.Component />
                          </LoginRoute>
                        ) : !route.isPublic ? (
                          <ProtectedRoute>
                            <route.Component />
                          </ProtectedRoute>
                        ) : (
                          <route.Component />
                        )
                      }
                    />
                  );
                })}
                <Route path="*" element={<NotFound />} />
              </Routes>
          </Main>
        </IonApp>
      )}

      {loaderTemplate && (
        <section className={`animated loadr ${!loader ? "fadeOutDown" : ""}`}>
          <div className="box-load">
            <section className="cn-img">
              <img src={gif} />
            </section>
            <ArsaSpinner name={"dots"} extraClass="clr" />
          </div>
        </section>
      )}
    </>
  );
};

export default App;
