import React, { useEffect, useState } from "react";
import "./header.scss";
import logo from "./../../assets-site/imgs/logos/logoOlam.png";
import { useTranslation } from "react-i18next";
import { EventService } from "arsa";
import { useNavigate } from "react-router-dom";

function Header({
  menues = [],
  upActive = false,
  isBackActive = false,
}: {
  menues: any;
  upActive?: boolean;
  isBackActive?: boolean;
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {}, []);

  return (
    <section className={`hrt ${upActive ? "hrts" : ""}`}>
      <section className={`head ${upActive ? "fixd" : ""}`} id="hdr">
        <div className="arsa-logo">
          { isBackActive && <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <span className="material-icons">arrow_back_ios</span>
          </div>}
          <img src={logo} alt="arsa-logo" />
          <p>OlamSys</p>
        </div>

        {!isBackActive &&
          menues.map((menu: any, index: number) => {
            return (
              <section
                className="menu"
                key={`hdr-${index}`}
                onClick={menu.onClick}
              >
                {t(menu.name)}
              </section>
            );
          })}

        {!isBackActive && (
          <button
            className="menuside"
            onClick={() => {
              EventService.send("openMenu", true);
            }}
          >
            <span className="material-icons">apps</span>
          </button>
        )}
      </section>
    </section>
  );
}

export default Header;
