//export const path = "http://localhost/medicappapi/";//LOCAL
export const path = "https://www.minvite.mx/marryme/";//QA
//export const medic = "medic/";//LOCAL
export const medic = "marry/";
//export const medic = "medic/medic.php/";//qa
export const emulate: boolean = false;
export const idEmpresa: number = 1; //This is ARSA

export const environment = {
  genericQuerie: `${path}${medic}generic-querie`,
  petition: `${path}${medic}petition/`,
  sendMail: `${path}${medic}sendMail/`,
};

export const themeData = {
  blueStrong: {
    background: "#154d5e",
    color: "#f2f2f2",
    disabledColor: "#ddd4d4",
    enabledColor: "#ffffff",
    component: "#03454f",
    alert_class: "theme1",
    componentColor: "#f2f2f2",
    primaryColorTab: "primary7",
    primaryColorTabs: "primary",
    primaryTabLetter: "light",
    sheet: "sheet1",
  },
  black: {
    background: "#484848",
    color: "#f2f2f2",
    disabledColor: "#ddd4d4",
    enabledColor: "#ffffff",
    component: "#242525",
    alert_class: "theme2",
    componentColor: "#f2f2f2",
    primaryColorTab: "primary8",
    primaryColorTabs: "primary2",
    primaryTabLetter: "light",
    sheet: "sheet2",
  },
  blue: {
    background: "#5c98fa",
    color: "#f2f2f2",
    disabledColor: "#ddd4d4",
    enabledColor: "#ffffff",
    component: "#4184f7",
    alert_class: "theme3",
    componentColor: "#f2f2f2",
    primaryColorTab: "primary9",
    primaryColorTabs: "primary3",
    primaryTabLetter: "light",
    sheet: "sheet3"
    //#75a9fd
  },
  purple: {
    background: "#530751",
    color: "#f2f2f2",
    disabledColor: "#ddd4d4",
    enabledColor: "#ffffff",
    component: "#310130",
    alert_class: "theme4",
    componentColor: "#f2f2f2",
    primaryColorTab: "primary10",
    primaryColorTabs: "primary4",
    primaryTabLetter: "light",
    sheet: "sheet4",
  },
  green: {
    background: "#316643",
    color: "#FFF",
    disabledColor: "#ddd4d4",
    enabledColor: "#ffffff",
    component: "#53a16e",
    componentColor: "#e3e3e3",
    alert_class: "theme5",
    primaryColorTab: "primary11",
    primaryColorTabs: "primary5",
    primaryTabLetter: "dark",
    sheet: "sheet5",
  },
  white: {
    background: "#bcbcbc",
    color: "#1d1d1d",
    disabledColor: "#ddd4d4",
    enabledColor: "#ffffff",
    component: "#6f6f6f",
    alert_class: "theme6",
    componentColor: "#f2f2f2",
    primaryColorTab: "primary12",
    primaryColorTabs: "primary6",
    primaryTabLetter: "light",
    sheet: "sheet6",
  },
};

export const eventKeys = {
  loader: "loader",
  closeDrawer: "closeDrawer",
  menuGlobal: "menuGlobal",
};

export const localStorageKeys = {
  menuOpen: "menuOpen",
};

export const SYSTEM_CONSTANTS = {
  mobileMinimum: 900,
  drawerWidth: 300,
  loginTemplate: "OlamSys",
};

export const firebaseConfig = {
  apiKey: "AIzaSyB2iqGzmleN_QSnljcqNIO8LXyh1GRTsWU",
  authDomain: "medicapp-8e7c8.firebaseapp.com",
  projectId: "medicapp-8e7c8",
  storageBucket: "medicapp-8e7c8.appspot.com",
  messagingSenderId: "940933332226",
  appId: "1:940933332226:web:4ce92e363167aa1193161b",
  measurementId: "G-20VHLSGXDT"
};

