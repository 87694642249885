import {
  HttpService,
  SqlGenericService,
  LocalStorageEncryptService,
} from "arsa";
export class HomePresenter {
  static findBanner(setBanner) {
    let sql: string = `SELECT * FROM page_banner`;
    SqlGenericService.excecuteQueryStringReference(sql, "getBanners").then(
      (response: any) => {
        setBanner(response.data.data);
      }
    );
  }

  static findFeatures(setFeatures) {
    let sql: string = `SELECT * FROM page_feature`;
    SqlGenericService.excecuteQueryStringReference(sql, "getFeatures").then(
      (response: any) => {
        setFeatures(response.data.data); //setBanner(response.data);
      }
    );
  }

  static findBelt(setBelt, i18n) {
    let sql: string = `SELECT * FROM page_belt`;
    SqlGenericService.excecuteQueryStringReference(sql, "getBelts").then(
      (response: any) => {
        console.log(response);
        
        let belts: any = [];
        response.data.data.forEach((b: any) => {
          b.title = i18n.language == "en" ? b.title_en : b.title_es;
          b.description =
            i18n.language == "en" ? b.dscription_en : b.description_es;
            belts.push(b);
        });

        console.log(belts);
        
        setBelt(belts); //setBanner(response.data);
      }
    );
  }

  static findCofounders(setFounders) {
    let sql: string = `SELECT * FROM page_team`;
    SqlGenericService.excecuteQueryStringReference(sql, "getTeam").then(
      (response: any) => {
        setFounders(response.data.data); //setBanner(response.data);
      }
    );
  }

  static findPrices(setPrices) {
    let sql: string = `SELECT * FROM page_pricing`;
    SqlGenericService.excecuteQueryStringReference(sql, "getPrices").then(
      (response: any) => {
        let prices:any = [];
        console.log("--------------");
        console.log(response.data.data);
        
        response.data.data.forEach(element => {
          console.log(element);
            element.json_en = JSON.parse(element.json_en.trim().slice(2, -2));
            console.log(element);
            
            element.json_es = JSON.parse(element.json_es.trim().slice(2, -2));
            prices.push(element);
        });
        setPrices(prices); //setBanner(response.data);
      }
    );
  }

}
