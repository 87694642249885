import { useEffect, useRef, useState } from "react";
import "./contact-form.scss";
import { useTranslation } from "react-i18next";
import ArsaMap from "../arsa-map/ArsaMap";
import { AlertService, ArsaForm, HttpService, LoadingService, environment } from 'arsa';
import { ContactPresenter } from "./presenter/Contact.presenter";

function ContactForm({
  extraClass,
  form,
  title,
  description,
}: {
  extraClass?: string;
  title?: string;
  description?: string;
  form?: any[];
}) {
  useEffect(() => {}, []);
  const { t } = useTranslation();
  const formRef = useRef(new Date().getTime());
  const [formContact, setFormContact] = useState<any>([
    {
      id:0,
      placeholder: t('contact.name'),
      value: "",
      type:"text"
    },{
      id:1,
      placeholder: t('contact.email'),
      value: "",
      type:"text"
    },{
      id:2,
      placeholder: t('contact.msj'),
      value: "",
      type:"textarea"
    }
  ]);

  function sendMail(){
    ContactPresenter.sendMail(formContact);
  }
  
  return (
    <section className={`contact-form ${extraClass}`}>
      <div className="max">
        <div className="int">
          <section className="maps">
            <p>{t("home.where")}</p>
            <ArsaMap />
          </section>

          <section className="leseparat">
            <p>{t("contact.title")}</p>
            
                <ArsaForm form={form} extraClass={'contact-frm'} data={formContact} buttonText={t("contact.send")} buttonAction={()=>{
                  console.log(formContact);
                  
                  let request: any = {
                    asunto: "OlamSys",
                    from: "contacto@olam-systems.com.mx",
                    name: formContact[0].value,
                    to: "sarrejuan@gmail.com",
                    cuerpo: `Desde OlamSys, contactame datos ${JSON.stringify(formContact)}`,
                  };
        
                  HttpService.post(`${environment.environmentData.sendEmail}`, request)
                    .then((responseFile: any) => {
                      LoadingService.hide();
                      let cop = [...formContact];
                      cop.forEach(element => {
                        element.value = "";
                        try {
                          (document.getElementById(element.id) as any).value = "";
                        } catch (error) {
                          
                        }
                      });

                      AlertService.bottomModals(
                        t("alerts.gud"),
                        t("alerts.de")
                      );

                    })
                    .catch((e) => {
                      LoadingService.hide();
                      AlertService.bottomModals(
                        t("alerts.error"),
                        t("alerts.embarrassing")
                      );
                    });
                }}/>

          </section>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
