import { useEffect, useState } from "react";
import "./arsa-map.scss";
import { EventService, subscriptions } from "arsa";
declare let L;
import logo from "../../assets_site/images/logoOlam.png";

function ArsaMap({
  extraClass,
}: {
  extraClass?: string;
}) {
  const [aditionalData, setAditionalData] = useState({
    lat: 22.921226, 
    long: -98.067401,
  });
  const [markerRef, setMarkerRef] = useState<any>(null);
  const [map, setMap] = useState<any>(null);

  useEffect(() => {
    initMap();
  }, []);

  function initMap() {
    subscriptions["updatePointMap"] = EventService.get(
      "updatePointMap"
    ).subscribe((data: any) => {
      let mkt: any = { ...markerRef };
      let mp: any = { ...map };
      mkt.setLatLng([data.lat, data.lon]);
      mp.panTo(new L.LatLng(data.lat, data.lon));

      setMarkerRef(mkt);
      setMap(mp);
    });

    setTimeout(() => {
      cargaMapa();
    }, 1000);
  }

  function cargaMapa() {
    //this.loadingService.show();

    let mkt: any = { ...markerRef };
    let mp: any = { ...map };
    let addData: any = { ...aditionalData };

    let greenIcon = new L.Icon({
      iconUrl: "https://olam-systems.com.mx/logoOlam.png",
      iconSize: [30, 30],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    });

    if (!addData.lat || addData.lat.length <= 0) {
      addData.lat = 22.28519;
      addData.long = -97.87777;
    }
    mp = L.map("map").setView([addData.lat, addData.long], 15);

    let a: any = document.getElementsByClassName("leaflet-control-attribution");
    setTimeout(() => {
      a[0].children[0].style.display = "none";
    }, 500);

    // set map tiles source

    let osmLayer = new L.tileLayer(
      "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
      {
        maxZoom: 19,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
        attribution:
          'Mapa OlamSys &copy; <a href="https://www.olam-systems.com.mx/">Olam Systems</a>',
      }
    );

    mp.addLayer(osmLayer);

    // add marker to the map
    mkt = new L.marker([addData.lat, addData.long], {
      draggable: true,
      icon: greenIcon,
      //icon: this.getIcon()
      //iconSize: [40,40]
    }).addTo(mp);

    console.log(mkt);

    mkt.dragging.enable();
    // var circle = new L.circle([addData.lat, addData.long], 500).addTo(mp);

    mkt.on("dragend", (e) => {
      let marker = e.target;
      let position = marker.getLatLng();
      mp.panTo(new L.LatLng(position.lat, position.lng));
      //circle.setLatLng(new L.LatLng(position.lat, position.lng));
    });
  }

  return (
    <section className="mapa">
      <div className="input-container" style={{ width: "100%" }}>
        <div id="map" style={{ width: "100%", height: "300px" }}></div>
      </div>
    </section>
  );
}

export default ArsaMap;
