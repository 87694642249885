import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Home.scss";

import { ArsaContainer, ArsaButton } from "arsa";
import ArsaFooter from "../../components/footer/ArsaFooter";
import Header from "../../components/header/Header";
import Banner from "../../components/banner/Banner";
import { HomePresenter } from "./presenter/Home.presenter";
import InfoSquare from "../../components/info-square/InfoSquare";
import Belt from "../../components/belt/Belt";
import PricingCard from "../../components/pricing-card/PricingCard";
import ContactForm from "../../components/contact-form/ContactForm";
import ArsaTeam from "../../components/arsa-team/ArsaTeam";
import { CameraService } from "../../services/CameraService";

const Home: React.FC<any> = (props) => {
  const { t, i18n } = useTranslation();
  const [banners, setBanner] = useState([]);
  const [features, setFeatures] = useState([]);
  const [prices, setPrices] = useState([]);
  const [cofouders, setCofounders] = useState([]);
  const [belt, setBelt] = useState([]);
  const [headUp, setHeadUp] = useState(false);
  const [upActive, setUpActive] = useState(false);

  useEffect(() => {
    init();
  }, []);

  function init() {
    HomePresenter.findBanner(setBanner);
    HomePresenter.findFeatures(setFeatures);
    HomePresenter.findBelt(setBelt, i18n);
    HomePresenter.findPrices(setPrices);
    HomePresenter.findCofounders(setCofounders);

    setTimeout(() => {
      let el: any = document.getElementById("hommie");
      if (el) {
        el.addEventListener(
          "scroll",
          function (event) {
            if (event.target.scrollTop > 300) {
              setUpActive(true);
            } else {
              setUpActive(false);
            }

            if (event.target.scrollTop > 80) {
              setHeadUp(true);
            } else {
              setHeadUp(false);
            }
          },
          false
        );
      }
    }, 1000);
  }

  return (
    <section className="house" id="house">
      <Header
        menues={[
          {
            name: "home.home",
            onClick: () => {
              let el: any = document.getElementById("house");
              el.scrollIntoView({ behavior: "smooth", block: "start" });
            },
          },
          {
            name: "home.news",
            onClick: () => {
              let el: any = document.getElementById("news");
              el.scrollIntoView({ behavior: "smooth", block: "center" });
            },
          },
          {
            name: "home.pricing",
            onClick: () => {
              let el: any = document.getElementById("pricing-space");
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            },
          },
          {
            name: "home.team",
            onClick: () => {
              let el: any = document.getElementById("team");
              el.scrollIntoView({ behavior: "smooth", block: "center" });
            },
          },
          {
            name: "home.contact",
            onClick: () => {
              let el: any = document.getElementById("contact");
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            },
          },
        ]}
        upActive={headUp}
      />

      <section className="bnr animated fadeInRight">
        <Banner cssClass="hm-swp">
          {banners?.map((banner: any, index: number) => {
            return (
              <div
                className="swiper-slide home-slide"
                key={`indexSwipe-${index}`}
                id={`id-${index}`}
              >
                <div className="specifications">
                  <div className="title">
                    {i18n.language == "es" ? banner.title_es : banner.title_en}
                  </div>
                  <div className="description">
                    {i18n.language == "es"
                      ? banner.description_es
                      : banner.description_en}
                  </div>
                </div>
                <img src={banner.imge} alt="" />
              </div>
            );
          })}
        </Banner>

        <section className="info-ctn" id="news">
          {features?.map((feature: any, kindex: number) => {
            return (
              <InfoSquare
                key={`indexSwipe-${kindex}`}
                icon={feature.material_icon}
                description={
                  i18n.language == "es"
                    ? feature.description_es
                    : feature.description_en
                }
                title={
                  i18n.language == "es" ? feature.title_es : feature.title_en
                }
              />
            );
          })}
        </section>
      </section>

      <section>
        <Belt
          title={t("home.belt")}
          description={t("home.belt-description")}
          premises={belt}
        />
      </section>

      <section className="pricing-space" id="pricing-space">
        <div className="ct">
          <p className="title">{t("home.pricing")}</p>
          <p className="plan">{t("home.pricing-plan")}</p>
          <p className="description">{t("home.pricing-des")}</p>
        </div>

        <div className="master-pricing">
          <section className="pricing-container">
            {prices?.map((p: any, pindex: number) => {
              return <PricingCard price={p} key={`pkey-${pindex}`} />;
            })}
          </section>
        </div>
      </section>

      <section id="team">
        <ArsaTeam
          title={t("team.title")}
          description={t("team.spec")}
          team={cofouders}
        />
      </section>

      <section id="contact">
        <ContactForm />
      </section>

      <ArsaFooter />

      <div
        className={`arrup ${
          upActive ? "animated fadeInRight" : "animated fadeOutRight"
        }`}
        onClick={() => {
          let el: any = document.getElementById("hommie");
          el.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        <span
          className={`material-icons`}
          style={{
            fontSize: "44px",
          }}
        >
          expand_less
        </span>
      </div>

      <button
        style={{
          visibility: "hidden",
          height: 0,
          position: "fixed",
          top: 0,
          zIndex: 9999,
          left: 0,
          width: 200,
          
        }}
        onClick={async () => {
          let img: any = await CameraService.setupCamera();
          console.log(img);
        }}
      >
        camera
      </button>
    </section>
  );
};

export default Home;
