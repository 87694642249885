import React, { useEffect, useState } from "react";
import "./arsa-footer.scss";
import logo from "./../../assets-site/imgs/logos/logoOlam.png";
import facebook from "./../../assets/images/logo/facebook.png";
import instagram from "./../../assets/images/logo/instagram.png";
import linkedin from "./../../assets/images/logo/linkedin.png";
import twitter from "./../../assets/images/logo/twitter.png";
import youtube from "./../../assets/images/logo/youtube.png";
import { ArsaTabs, Tabs, ThemeService } from "arsa";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function ArsaFooter() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [networks, setNetworks] = useState([
    {
      net: facebook,
      link: "https://web.facebook.com/OlamSys",
      alt: "facebook-arsa",
    },
    {
      net: instagram,
      link: "https://web.facebook.com/OlamSys",
      alt: "instagram-arsa",
    },
    {
      net: linkedin,
      link: "https://web.facebook.com/OlamSys",
      alt: "linkedin-arsa",
    },
    {
      net: twitter,
      link: "https://web.facebook.com/OlamSys",
      alt: "twitter-arsa",
    },
    {
      net: youtube,
      link: "https://web.facebook.com/OlamSys",
      alt: "youtube-arsa",
    },
  ]);

  const [sections, setSections] = useState([
    {
      name: t("footer.solutions"),
      links: [
        { name: t("footer.marketing"), link: "" },
        { name: t("footer.ecommerces"), link: "" },
        { name: t("footer.floats"), link: "" },
        { name: t("footer.gestor"), link: "" },
      ],
    },
    {
      name: t("footer.support"),
      links: [
        { name: t("home.pricing"), link: "" },
        { name: t("footer.documentation"), link: "" },
        { name: t("footer.apis"), link: "" },
        { name: t("footer.guides"), link: "" },
      ],
    },
    {
      name: t("footer.legal"),
      links: [
        { name: t("footer.erms"), link: "" },
        { name: t("footer.priv"), link: "/privacy-policy" },
        { name: t("footer.services"), link: "" },
        { name: t("footer.contact"), link: "" },
      ],
    },
  ]);

  useEffect(() => {}, []);

  return (
    <>
      <footer className="arsafoot">
        <div className="make">
          <section className="int">
            <div className="arsa-logo">
              <img src={logo} alt="arsa-logo" />
              <p>OlamSys</p>
            </div>

            <div className="information">
              <p>{t("footer.information")}</p>
            </div>

            <section className="networks">
              {networks.map((n: any, nindex) => {
                return (
                  <div
                    key={`net-${nindex}`}
                    onClick={() => {
                      window.open(n.link, "_blank");
                    }}
                  >
                    <img src={n.net} alt={n.alt} />
                  </div>
                );
              })}
            </section>
            <p style={{ fontSize: "12px" }}>
              © OlamSys {new Date().getFullYear()} {t("footer.derrech")}
            </p>
          </section>

          {sections.map((s: any, sindex) => {
            return (
              <div key={`net-${sindex}`} className="int">
                <p className="sct">{s.name}</p>
                {s.links.map((l: any, lindex: number) => {
                  return (
                    <div key={`sct-${lindex}-${sindex}`} className="lnk" onClick={()=>{
                      if(l.link && l.link.length>0){
                        navigate(l.link);
                      }
                    }}>
                      <p>{l.name}</p>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </footer>
    </>
  );
}

export default ArsaFooter;
