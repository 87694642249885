import { useEffect } from "react";
import "./info-square.scss";

declare let Swiper;

function InfoSquare({
  extraClass,
  icon,
  title,
  description
}: {
  extraClass?: string;
  title?: string;
  description?: string;
  icon?: string;
}) {
  useEffect(() => {
  }, []);

  return (
    <section className={`info-square ${extraClass}`}>
      <div>
        <span className="material-icons">{icon}</span>
      </div>
      <p className="title">{title}</p>
      <p>{description}</p>
    </section>
  );
}

export default InfoSquare;
